import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout bgColor="#fd9d6e">
    <SEO title="404: Not found" />
    <div style={{ height: `100vh` }}>
      <h1 style={{ fontSize: `200px` }}>404</h1>
      <Link
        style={{
          color: `var(--text-color-dark)`,
          borderBottom: `1px solid var(--text-color-dark)`,
        }}
        to="/"
      >
        home/inicio
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
